<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <!-- <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert> -->

    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="mr-2">
                      <b-avatar
                        size="lg"
                        src="../../assets/images/avatars/1-small.png"
                      />
                    </div>
                    <div>
                      <h5 class="bold">Expert Name</h5>
                    </div>
                  </div>
                </div>
                <div class="mt-0">
                  <p class="card-text mb-25">
                    Office 149, 450 South Brand Brooklyn
                  </p>
                  <p class="card-text mb-25">San Diego County, CA 91905, USA</p>
                  <p class="card-text mb-0">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </p>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="d-flex align-items-center mb-1">
                  <h4 class="title">Invoice</h4>
                  <b-form-input id="industry" class="w-50" placeholder="" />
                </div>
                <div class="d-flex align-items-center mb-1">
                  <p class="title">Date</p>
                  <b-form-input id="industry" class="w-50" placeholder="" />
                </div>
                <div class="d-flex align-items-center mb-1">
                  <p class="title">Due Date</p>
                  <b-form-input id="industry" class="w-50" placeholder="" />
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" lg="6" class="p-0">
                <h6 class="mb-2">Invoice To:</h6>
                <h6 class="mb-25">Venture Executive Partners</h6>
                <p class="card-text mb-25">Samll Health, B10 OHF, UK</p>
                <p class="card-text mb-25">72B-986-6062</p>
                <p class="card-text mb-25">pikyFBliders@gmail.com</p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                lg="6"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">Bill For:</h6>
                  <h6 class="mb-25">Venture Executive Partners</h6>
                  <p class="card-text mb-25">Samll Health, B10 OHF, UK</p>
                  <p class="card-text mb-25">72B-986-6062</p>
                  <p class="card-text mb-25">pikyFBliders@gmail.com</p>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <!-- <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="['taskDescription', 'rate', 'hours', 'total']"
          >
            <template #cell(taskDescription)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.taskTitle }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.taskDescription }}
              </b-card-text>
            </template>
          </b-table-lite> -->
          <b-card>
            <div class="">
              <b-form
                ref="form"
                class="repeater-form bg-white"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row id="item.id" ref="row" class="mx-lg-1">
                  <!-- Item Name -->
                  <b-col md="6">
                    <b-form-group label="Details" label-for="details">
                      <b-form-textarea
                        id="textarea-rows"
                        placeholder="Write Here..."
                        rows="2"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Cost -->
                  <b-col md="2">
                    <b-form-group label="Hourly Charge($)" label-for="cost">
                      <b-form-input id="cost" type="number" placeholder="32" />
                    </b-form-group>
                  </b-col>

                  <!-- Quantity -->
                  <b-col md="2">
                    <b-form-group label="Hours" label-for="hours">
                      <b-form-input
                        id="quantity"
                        type="number"
                        placeholder="1"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Profession -->
                  <b-col lg="2" md="1">
                    <b-form-group label="Total Charge" label-for="total-charge">
                      <b-form-input
                        id="total-charge"
                        plaintext
                        value="$24.00"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <!-- <b-col lg="2" md="3" class="mb-50">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Delete</span>
                    </b-button>
                  </b-col> -->
                </b-row>
              </b-form>
            </div>
          </b-card>
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding ml-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-start"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Tax:</p>
                    <p class="invoice-total-amount">21%</p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">$1690</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <!-- Note -->
          <b-card-body class="invoice-padding pt-0 pb-3">
            <span class="font-weight-bold">Note: </span>
            <b-form-input id="note" placeholder="Thanks for your business" />
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Preview
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Save
          </b-button>
          <b-button
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
            Add Payment
          </b-button>
        </b-card>
        <b-card>
          <h4>Accept Payment Via</h4>
          <b-form-select v-model="selectPayment" :options="paymentOptions" />

          <div class="d-flex justify-content-between mb-1 mt-3">
            <h4>Payment Terms</h4>
            <b-form-checkbox checked="true" name="check-button" switch inline />
          </div>
          <div class="d-flex justify-content-between mb-1">
            <h4>Clients Note</h4>
            <b-form-checkbox checked="true" name="check-button" switch inline />
          </div>
          <div class="d-flex justify-content-between">
            <h4>Payment Stub</h4>
            <b-form-checkbox checked="true" name="check-button" switch inline />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
// import router from '@/router';
import Logo from '@core/layouts/components/Logo.vue';
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BLink,
  BRow,
  BTableLite,
  VBToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  components: {
    BRow,
    BForm,
    BFormGroup,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BFormInput,
    BButton,
    BAlert,
    BFormTextarea,
    BLink,
    BAvatar,
    BFormCheckbox,
    Logo,
    BFormSelect,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  data() {
    return {
      paymentOptions: [
        { value: 'visa-card', text: 'Visa Card' },
        { value: 'master-card', text: 'Master Card' },
      ],
      selectPayment: '',
    };
  },
  //   setup() {
  //     const invoiceData = ref(null);
  //     const paymentDetails = ref({});

  //     // Invoice Description
  //     // ? Your real data will contain this information
  //     const invoiceDescription = [
  //       {
  //         taskTitle: 'Native App Development',
  //         taskDescription:
  //           'Developed a full stack native app using React Native, Bootstrap & Python',
  //         rate: '$60.00',
  //         hours: '30',
  //         total: '$1,800.00',
  //       },
  //       {
  //         taskTitle: 'UI Kit Design',
  //         taskDescription:
  //           'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
  //         rate: '$60.00',
  //         hours: '20',
  //         total: '$1200.00',
  //       },
  //     ];

  //     // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice';

  //     // Register module
  //     // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
  //     //   store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

  //     // UnRegister on leave
  //     // onUnmounted(() => {
  //     //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
  //     //     store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
  //     // });

  //     store
  //       .dispatch('app-invoice/fetchInvoice', {
  //         id: router.currentRoute.params.id,
  //       })
  //       .then((response) => {
  //         invoiceData.value = response.data.invoice;
  //         paymentDetails.value = response.data.paymentDetails;
  //       })
  //       .catch((error) => {
  //         if (error.response.status === 404) {
  //           invoiceData.value = undefined;
  //         }
  //       });

  //     const printInvoice = () => {
  //       window.print();
  //     };

  //     return {
  //       invoiceData,
  //       paymentDetails,
  //       invoiceDescription,
  //       printInvoice,
  //     };
  //   },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.title {
  width: 35%;
  margin: auto 0;
}
</style>
